import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()
import 'stylesheets/towerhamletslocaloffer/application.scss'
import 'javascripts/glide'
import 'javascripts/navbar'
import 'javascripts/cms_pages'
import 'javascripts/blog_article_browser'
import 'javascripts/organisations'
import 'javascripts/organisation_finder'
import 'javascripts/events'
import 'javascripts/event_browser'
import 'javascripts/favourites'
import 'javascripts/glide_quotes'
import 'javascripts/site_searches'
import 'javascripts/was_this_useful'
import 'javascripts/survey_modal'
import 'javascripts/documents'
import 'javascripts/form_validation'
import 'javascripts/opportunities'
import 'javascripts/quotes'
import 'javascripts/non_cms_dropdown_headers'

import Glide, { Controls, Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

function ready(callback){
  // in case the document is already rendered
  if (document.readyState!='loading') callback();
  // modern browsers
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  // IE <= 8
  else document.attachEvent('onreadystatechange', function(){
      if (document.readyState=='complete') callback();
  });
}

ready(function(){

    document.querySelector('a[rel~="external"]').addEventListener("click", function(e) {
      if(typeof ga !== 'undefined') {
        let url = e.target.href;
        ga('send', 'event', 'Outbound', 'Click', url, {
          'transport': 'beacon'
        });
      }
    });

    setTimeout(function(){
      var glide = new Glide('.glide', {
        hoverpause: false,
        perView: 1,
        autoplay:false
      }).mount({ Controls, Autoplay })

      var play = document.querySelector("#js_glide_play");
      var pause = document.querySelector("#js_glide_pause");

      play.addEventListener('click', function () {
        glide.play();
      })

      pause.addEventListener('click', function () {
        glide.pause();
      })

      new Glide('.glide-quotes', {
        type: 'carousel',
        hoverpause: false,
        perView: 1,
        autoplay:false,
        gap: 50,
      }).mount({ Controls, Autoplay, Swipe })

    }, 5000);
  })

// sub menu hover and keyboard functionality
ready(function(){
    // delays in milliseconds
	  let showDelay = 200, hideDelay = 500;
	  // holding variables for timers
	  let menuEnterTimer, menuLeaveTimer;

    let subMenuItems = document.querySelectorAll('li.has-submenu');

    Array.prototype.forEach.call(subMenuItems, function(el, i) {
      el.addEventListener("mouseover", function(event) {
        let thisItem = this;
        clearTimeout(menuLeaveTimer);

        menuEnterTimer = setTimeout(function() {
          thisItem.classList.add("open");
          thisItem.setAttribute('aria-expanded', 'true');
        }, showDelay);
      })

      el.addEventListener("mouseout", function(event) {
        let thisItem = this;
        clearTimeout(menuEnterTimer);

        menuLeaveTimer = setTimeout(function() {
          thisItem.classList.remove("open");
          thisItem.setAttribute('aria-expanded', 'false');
        }, hideDelay);

      })

      el.querySelector('button').addEventListener("click",  function(event){
        if (!this.parentNode.classList.contains('open')) {
          this.parentNode.classList.add('open');
          this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
          this.parentNode.setAttribute('aria-expanded', 'true');
        } else {
          this.parentNode.classList.remove('open');
          this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
          this.parentNode.setAttribute('aria-expanded', 'false');
        }
        event.preventDefault();
      });
    });
  }
)
