export const flatpickr_config_past_dates = {
  data() {
    return {
      config: {
        altFormat: "j F, Y",
        altInput: true,
        maxDate: "today"
      }
    }
  }
}
