document.addEventListener('DOMContentLoaded', () => {

let getNextSiblings = function (e) {

  let siblings = [];

  let sibling = e.nextElementSibling;

  while (sibling) {
    siblings.push(sibling);
    sibling = sibling.nextElementSibling;
  }

  return siblings
};

  let i = 1;

  const HEADERS = document.querySelectorAll('.non-cms-dropdown-header');

  for (let item of HEADERS) {
    if (item.innerText.trim().length > 0) {
      const REGION_ID = `region-${i}`;
      const BUTTON_ID = `item-${i}`;

      const NEW_BUTTON = document.createElement("button");
      NEW_BUTTON.appendChild(document.createTextNode(item.innerText));
      NEW_BUTTON.id = BUTTON_ID;
      NEW_BUTTON.setAttribute("aria-expanded", false);
      NEW_BUTTON.setAttribute("aria-controls", REGION_ID);
      NEW_BUTTON.classList.add("dropdown-button");
      NEW_BUTTON.classList.add("d-block");

      item.innerText = "";

      item.parentNode.insertBefore(NEW_BUTTON, item);

      const NEW_REGION = document.createElement("div");
      NEW_REGION.id = REGION_ID;
      NEW_REGION.setAttribute("aria-labelledby", BUTTON_ID);
      NEW_REGION.setAttribute("role", 'region');
      NEW_REGION.classList.add("hidden", "header-inner");

      const REGION_ELS = getNextSiblings(item);
      REGION_ELS.forEach(el => NEW_REGION.appendChild(el));
      item.parentNode.insertBefore(NEW_REGION, item);
      item.innerText = i;

      i++;
    }
  }

  for (let item of HEADERS) {
    item.parentNode.removeChild(item);
  }

  //toggle hidden/unhidden with dropdown button

  document.querySelectorAll('.section-fieldset .dropdown-button').forEach(item => {
    item.addEventListener('click', event => {
      let region = item.nextElementSibling;
      if(item.getAttribute("aria-expanded") == "true") {
        item.setAttribute("aria-expanded", "false");
      } else {
        item.setAttribute("aria-expanded", "true");
      }
      region.classList.toggle('hidden');

    })
  })



});
