import Vue from 'vue/dist/vue.esm';
// import axios from 'axios';
import {focus_trap_mixin} from 'javascripts/mixins/focus_trap_mixin.js';
import {js_loaded_mixin} from 'javascripts/mixins/js_loaded_mixin.js';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("js-survey-modal")) {
    window.onkeyup = function (event) {
     if (event.keyCode == 'escape') {
       document.getElementById(boxid).style.visibility="hidden";
     }
    }

    Vue.component('survey_modal', {
      mixins: [focus_trap_mixin, js_loaded_mixin],
      methods: {
        closeModal() {
          this.toggleOverlay();
        },
        getCookie(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        }
      },
      computed: {
        focusableItems() {
          const CLASS_NAME = '.js-survey-modal-focusable';

          return document.querySelectorAll(`a[href]:not(${CLASS_NAME}), input:not(${CLASS_NAME}), button:not(${CLASS_NAME}), select, textarea, iframe, [tabindex], [contentEditable=true]`);
        }
      },
      created() {
        if (!this.getCookie('thlo_survey_shown')) {
          const vm = this;
          setTimeout(function() {
            document.cookie = 'thlo_survey_shown=true; expires=7';            
            vm.toggleOverlay();
          }, 60000);
        }
      }
    })

    new Vue({
      el: "#js-survey-modal"
    })
  }
});
